<template>
  <orders-list
    ref="ordersList"
    :headers="headers"
    :orders="orders"
    :loading="loading"
    :filters="filters"
    :options="options"
    :total-orders="totalOrders"
    :handle-collapse="handleCollapse"
  />
</template>

<script>
import OrdersRepository from '@/api/OrdersRepository'
import OrdersListMixin from '@/views/dashboard/pages/OrdersIndex/components/OrdersListMixin'
import OrdersList from '@/views/dashboard/lists/OrdersList'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'OrdersListWrapper',
  components: { OrdersList },
  mixins: [OrdersListMixin],
  data () {
    return {
      headers: [
        { text: capitalize(this.$t('entity')), value: 'entity.name' },
        { text: capitalize(this.$t('client')), value: 'client.name' },
        { text: capitalize(this.$t('status')), value: 'current_status' },
        { text: capitalize(this.$t('create')), value: 'created_at' },
        { text: capitalize(this.$t('actions')), value: 'action', sortable: false },
      ],
      filters: [
        {
          title: capitalize(this.$t('activity')),
          queryParam: 'open',
          collapsed: false,
          options: [
            {
              checked: true,
              value: 1,
              description: capitalize(this.$t('open')),
            },
            {
              checked: false,
              value: 0,
              description: capitalize(this.$t('finished')),
            },
          ],
        },
        {
          title: this.$t('responsibility'),
          queryParam: 'internal',
          collapsed: false,
          options: [
            {
              checked: false,
              value: 1,
              description: capitalize(this.$t('internal')),
            },
            {
              checked: false,
              value: 0,
              description: capitalize(this.$t('external')),
            },
          ],
        },
        {
          title: 'Status',
          queryParam: 'status',
          collapsed: false,
          options: [],
        },
      ],
      loading: true,
      isCollapse: false,
    }
  },
  async created () {
    await this.fetchOrderStatuses(2)
  },

  methods: {
    apiCall (params) {
      return OrdersRepository.index(params)
    },
  },
}
</script>

<style scoped>

</style>
