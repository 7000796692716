<template>
  <div v-if="user">
    <entity-orders-list-wrapper
      v-if="isEntityAdmin"
      :entity-id="user.entity_id"
    />
    <orders-list-wrapper
      v-else
    />
  </div>
</template>

<script>
import OrdersListWrapper from '@/views/dashboard/pages/OrdersIndex/components/OrdersListWrapper'
import EntityOrdersListWrapper from '@/views/dashboard/pages/OrdersIndex/components/EntityOrdersListWrapper'

export default {
  name: 'OrdersIndex',
  components: { EntityOrdersListWrapper, OrdersListWrapper },
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    isEntityAdmin () {
      if (!this.user) {
        return false
      }
      return this.user.user_role.name === 'entity_admin'
    },
  },
}
</script>

<style scoped>

</style>
